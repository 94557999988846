import React from "react"
import Container from "../container/container"
import styles from "./heading.module.scss"

const Heading = props => {
  return (
    <div
      className={styles.heading}
      data-sal="slide-right"
      data-sal-duration="2000">
      <Container>
        <h3>{props.subHeading}</h3>
        <h2>{props.heading}</h2>
      </Container>
    </div>
  )
}

export default Heading
