import React from "react"
import Container from "../container/container"
import styles from "./services.module.scss"
import { Link } from "gatsby"
import RightArrow from "../../images/arrows/right.svg"

const HomeServices = props => {
  function getSpeed(key) {
    let speed = 1000
    switch (key) {
      case 1:
        speed = 1000
        break
      case 2:
        speed = 1500
        break
      case 3:
        speed = 2000
        break
      default:
        speed = 1000
        break
    }
    return speed
  }

  return (
    <div>
      <Container>
        <div className={styles.services}>
          {props.services.map((service, key) => (
            <div
              className={styles.services__service}
              key={key}
              data-sal="fade"
              data-sal-duration={getSpeed(key)}>
              <div>
                <h2>{service.node.Title}</h2>
                <p>{service.node.Summary}</p>
              </div>
            </div>
          ))}
        </div>
        <div
          className={styles.services__linkWrap}
          data-sal="slide-up"
          data-sal-duration="2000">
          <Link to="/about" className={styles.services__linkWrap__link}>
            Find out more <img src={RightArrow} />
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default HomeServices
