import React from "react"
import BackgroundImage from "gatsby-background-image"
import styles from "./feature.module.scss"

const HomeFeature = props => {
  return (
    <BackgroundImage
      Tag="section"
      className={styles.feature}
      fluid={props.bgImage}>
      <div className={styles.feature__overlay}></div>
      <h2>
        <span data-sal="slide-right" data-sal-duration="1500">
          {props.lineOne}
        </span>
        <span data-sal="slide-left" data-sal-duration="2000">
          {props.lineTwo}
        </span>
      </h2>
    </BackgroundImage>
  )
}

export default HomeFeature
