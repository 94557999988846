import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styles from "./banner.module.scss"
import Container from "../container/container"
import Markdown from "react-markdown"
import MailIcon from "../../images/navbar/mail.svg"
import PhoneIcon from "../../images/navbar/phone.svg"
import BurgerIcon from "../../images/navbar/burger.svg"
import DownArrowIcon from "../../images/arrows/down.svg"

const query = graphql`
  query HomeBannerQuery {
    strapiHome {
      BannerHeading
      BannerBG {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
const HomeBanner = props => {
  const data = useStaticQuery(query)
  const bgImage = data.strapiHome.BannerBG.childImageSharp.fluid

  return (
    <BackgroundImage Tag="section" className={styles.banner} fluid={bgImage}>
      <Container>
        <div className={styles.banner__navbar}>
          <img
            src={props.logo}
            data-sal="slide-right"
            data-sal-duration="2000"
          />
          <div
            className={styles.banner__navbar__icons}
            data-sal="slide-left"
            data-sal-duration="2000">
            <a href="tel:01163183771">
              <img src={PhoneIcon} alt="Phone" />
            </a>
            <a href={`mailto:` + props.email}>
              <img src={MailIcon} alt="Mail" />
            </a>
            <img src={BurgerIcon} alt="Menu" onClick={props.openNav} />
          </div>
        </div>
        <div className={styles.banner__content}>
          <div
            className={styles.banner__content__wrap}
            data-sal="fade"
            data-sal-duration="2000">
            <Markdown source={data.strapiHome.BannerHeading}></Markdown>
            <Link to="/contact">
              <button>Start your project</button>
            </Link>
          </div>
          <img
            src={DownArrowIcon}
            alt="Down"
            data-sal="slide-up"
            data-sal-duration="2000"
            className={styles.banner__content__down}
          />
        </div>
      </Container>
    </BackgroundImage>
  )
}

export default HomeBanner
