import React, { useState } from "react"
import { graphql } from "gatsby"
import HomeBanner from "../components/home/banner"
import HomeIntro from "../components/home/intro"
import HomeServices from "../components/home/services"
import HomeFeature from "../components/home/feature"
import HomeCta from "../components/home/cta"
import Heading from "../components/heading/heading"
import Footer from "../components/footer/footer"
import SEO from "../components/seo"
import Nav from "../components/navigation/navigation"
import RecentWork from "../components/recent-work/recent-work"
import Testimonial from "../components/testimonial/testimonial"

const IndexPage = ({ data }) => {
  const [showNav, setShowNav] = useState(false)
  const homeData = data.strapiHome
  const globalData = data.strapiGlobal
  const bgClassTestimonials = "home"
  return (
    <div>
      <SEO title="One Creative" />
      <HomeBanner
        logo={globalData.logo.publicURL}
        email={globalData.email}
        openNav={() => setShowNav(true)}></HomeBanner>
      {showNav ? <Nav close={() => setShowNav(false)}></Nav> : null}
      <HomeIntro text={homeData.IntroText}></HomeIntro>
      <Heading
        heading={homeData.ServicesHeading}
        subHeading={homeData.ServicesSubHeading}></Heading>
      <HomeServices services={data.allStrapiService.edges}></HomeServices>
      <HomeCta ctaImage={homeData.CtaImage.childImageSharp.fluid}></HomeCta>
      <HomeFeature
        bgImage={homeData.FeatureBgImage.childImageSharp.fluid}
        lineOne={homeData.MessageLineOne}
        lineTwo={homeData.MessageLineTwo}></HomeFeature>
      <RecentWork
        data={data.allStrapiCaseStudies.edges}
        heading="Some of our recent work"
        limit={6}></RecentWork>
      <Testimonial bgClass={bgClassTestimonials}></Testimonial>
      <Footer
        logo={globalData.logo.publicURL}
        email={globalData.email}></Footer>
    </div>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    allStrapiCaseStudies {
      edges {
        node {
          id
          Title
          Slug
          FeaturedImage {
            publicURL
          }
          Thumbnail {
            publicURL
          }
        }
      }
    }
    strapiGlobal {
      email
      logo {
        publicURL
      }
    }
    strapiHome {
      IntroText
      ServicesHeading
      ServicesSubHeading
      FeatureText
      FeatureBgImage {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      CtaImage {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      MessageLineOne
      MessageLineTwo
    }
    allStrapiService {
      edges {
        node {
          Title
          Summary
        }
      }
    }
  }
`
