import React from "react"
import Container from "../container/container"
import styles from "./cta.module.scss"
import { Link } from "gatsby"

const HomeCta = props => {
  return (
    <div className={styles.cta}>
      <Container>
        <div className={styles.cta__wrap}>
          <div data-sal="fade" data-sal-duration="1500">
            <h2>
              Need to find customers? We create effective campaigns across
              traditional and digital media.
            </h2>
            <Link to="/contact">
              <button>Start your project</button>
            </Link>
          </div>
          <img
            src={props.ctaImage.src}
            alt="CTA Image"
            data-sal="slide-left"
            data-sal-duration="2000"
          />
        </div>
      </Container>
    </div>
  )
}

export default HomeCta
