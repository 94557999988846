import React from "react"
import Markdown from "react-markdown"
import Container from "../container/container"
import styles from "./intro.module.scss"

const HomeIntro = props => {
  return (
    <div className={styles.intro} data-sal="fade" data-sal-duration="2000">
      <Container>
        <Markdown source={props.text}></Markdown>
      </Container>
    </div>
  )
}

export default HomeIntro
